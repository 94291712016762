import React from "react";
import { graphql } from "gatsby";
import { Article } from "../models/article.model";
import PageWrapper from "../components/PageWrapper";
import { ROUTE_LINKS } from "../utils/routes";

const NewsArticle02 = (props: any) => {
  const article: Article = props.data.article.frontmatter;

  return (
    <PageWrapper path={ROUTE_LINKS.news}>
      <main className="flex flex-col max-w-6xl md:mx-auto px-2.5 py-12">
        <h2 className="font-bold text-xl mb-10 text-center">
          Matrix Rental Solutions Presenting at National Association of
          REALTORS® (NAR) Legislative Meeting in Washington DC, May 8-11
        </h2>
        <p className="italic font-light text-center text-lg mb-5">
          The Matrix Rental Solutions team, including founder and CEO Sipho
          Simela, will showcase the company’s innovative fintech platform —
          revolutionizing the rental housing market.
        </p>
        <p className="mb-5">
          <strong>STAMFORD, Conn.</strong>&nbsp;––&nbsp;April 21,
          2023&nbsp;––&nbsp;
          <a
            className="underline text-blue-500"
            href="https://mymatrixrent.com/"
          >
            Matrix Rental Solutions
          </a>
          , a Stamford, Connecticut-based technology company on a mission to
          improve access to fair and affordable housing, announced that they
          will exhibit at the National Association of REALTORS® (NAR)
          Legislative Meeting in Washington DC, May 6-11. The innovative
          proptech company will showcase its fintech platform at a REACH Alumni
          Tech Table in booth 421.
        </p>
        <p className="mb-5">
          The Matrix Rental Solutions team, including founder and CEO Sipho
          Simela, will be on-site to present and will discuss how the company’s
          Universal Rental Application and proprietary platform is
          revolutionizing the affordable housing rental market to NAR members
          seeking to advance the real estate industry, public policy, and the
          association. Matrix will engage in the special issues forums,
          committee meetings, legislative activities, and industry trade shows
          that make the REALTORS® Legislative Meeting such an important event.
        </p>
        <p className="mb-5">
          Matrix is a tenant analysis fintech platform that utilizes real-time
          data to produce an "ability to pay" score for prospective renters. By
          incorporating a variety of factors, including assets, income, and
          previous rental history, Matrix provides a more inclusive and accurate
          screening process that removes the potential for source of income
          discrimination and automates the underwriting process.
        </p>
        <p className="mb-5">
          “Traditional credit scoring can be statistically less inclusive for
          minorities, immigrants, and younger people, making it challenging for
          property owners to make informed decisions about renters,” said
          Simela. “ Some of the most urgent initiatives in the legislative cycle
          are directly related to problems that Matrix is solving, including
          source of income protections, tenant screening, and fair housing.”
        </p>
        <p className="mb-5">
          Each year, this event is attended by NAR members who serve on
          association committees and are active in the association's governance.
          Federal Political Coordinators (FPCs) and other politically engaged
          individuals who interact with congressional representatives on Capitol
          Hill attend the event as well.
        </p>
        <p className="mb-5">
          As Matrix data reflects, 89% of tenant rental applicants granted
          “conditional acceptance” made at least three consecutive on-time
          rental payments. These are applicants categorized as “hard reject”
          using traditional analysis models. Using a complete ability-to-pay
          score, Matrix streamlines the acceptance process and shortens it by up
          to 50%, helping more owners make their properties available to
          affordable housing and get tenants leased faster.
        </p>
        <p className="mb-5">
          To learn more, visit&nbsp;
          <a
            className="underline text-blue-500"
            href="https://www.mymatrixrent.com/"
          >
            https://www.mymatrixrent.com/
          </a>
        </p>
        <h5 className="mb-5 font-bold text-base">About Matrix</h5>
        <p className="mb-5">
          <a
            className="underline text-blue-500"
            href="https://www.mymatrixrent.com/"
          >
            Matrix Rental Solutions
          </a>
          &nbsp; offers a Universal Rental Application that is changing the
          landscape of the growing $500 billion rental housing market. Matrix
          features a single, reusable application with a single fee, powered by
          real-time data and producing an “ability to pay” score. Using a
          variety of factors, including assets, income and previous rental
          history, the result is more inclusive scoring that gives property
          managers faster, more accurate insights into the complete financial
          profile of a prospective tenant. Matrix removes the potential for
          source of income discrimination, taking the subsidy into consideration
          and automating the underwriting process.
        </p>
        <h5 className="mb-5 font-bold text-base">Media Contact:</h5>
        <p>
          Caroline Kamerschen
          <br />
          <a className="underline text-blue-500" href="caroline@bospar.com">
            caroline@bospar.com
          </a>
        </p>
      </main>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query($slug: Int) {
    article: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { category: { eq: "articles" } }
    ) {
      frontmatter {
        title
        date
        url
      }
    }
  }
`;

export default React.memo(NewsArticle02);
